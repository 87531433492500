import React, { useState } from "react";
import './App.css';

const App = () => {
  const [color, setColor] = useState("#000");

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  return (
    <div className="container">
      <input type="color" value={color} onChange={handleColorChange} />
      <div style={{ color: color }}> {color} </div>
    </div>
  );
};

export default App;
